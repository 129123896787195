// Bootstrap overrides
//
// Copy variables from `_variables.scss` to this file to override default values
// without modifying source files.

$gray-dark: #000048 !default;

$enable-flex: true;

$navbar-light-hover-color: #000048;

$brand-primary: #000048 !default;

$card-cap-bg: #000048 !default;

$card-cap-color: #ffffff;

$close-color: #ffffff;

$navbar-top-background: #000048 !default;
$navbar-bottom-background: #000048 !default;

$navbar-top-color: #fff;
$navbar-top-active-color: #e5e500;
$navbar-top-hover-color: #e2e2e2;

$navbar-bottom-color: #fff;
$navbar-bottom-active-color: #e5e500;
$navbar-bottom-hover-color: #e2e2e2;

$nav-tabs-link-active-color: #fff;
$nav-tabs-link-active-bg: #000048;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #182836 !default;
$red: #c7171e !default;
$yellow: #e5e500 !default;
$green: #365a7a !default;
$cyan: #548bde !default;

$mark-bg: $yellow !default;

$theme-colors: (
	primary: $blue,
	secondary: $gray-600,
	success: $green,
	info: $cyan,
	warning: $yellow,
	danger: $red,
	light: $gray-100,
	dark: $gray-800,
) !default;

@import '~bootstrap/scss/bootstrap.scss';

@import '~bootstrap/scss/bootstrap.scss';

@import '../../../../vendor/z5internet/demora/src/resources/assets/scss/scss.scss';

:root {
	/* Colors */
	--menu-black: #09090c;
	--menu-grey: #a4b2bc;
	--menu-white: #fff;
}

@media (min-width: 768px) {
	.authorisedUser #routerRoot {
		padding-left: 60px !important;
	}
}

@media (max-width: 767px) {
	body .authorisedUser #pageContent {
		margin-top: 35px;
	}
}

@media (max-width: 767px) {
	body.surfbar .authorisedUser #pageContent {
		margin-top: 0px;
	}
}

.btn-group-xss > .btn,
.btn-xss {
	padding: 3px 5px;
	font-size: 9px;
	line-height: 1.2;
	border-radius: 2px;
}

$blue: #182836 !default;
$red: #c7171e !default;
$yellow: #e5e500 !default;
$green: #365a7a !default;
$cyan: #548bde !default;
